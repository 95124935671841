'use client'

import { Spinner } from 'flowbite-react'
import Image, { ImageProps } from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'

type ImageWithLoaderProps = {
    spinnerSize: 'sm' | 'md' | 'lg' | 'xl',
    handleLoad?: () => void
} & ImageProps

export default function ImageWithLoader(props: ImageWithLoaderProps) {

    const [loading, setLoading] = useState(true)

    const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        setLoading(false)
        if (props.handleLoad)
            props.handleLoad()
    }

    useEffect(() => {
        setLoading(true)
    }, [props.src])

    return (
        <div className="relative w-full self-center aspect-square items-center justify-center flex" key={props.key}>
            {loading && <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 z-20 flex items-center justify-center">
                <span className="loading loading-spinner loading-lg bg-indigo-800" />
            </div>}
            <Image {...props} onLoad={handleLoad} />
        </div>
    )
}
