import { Properties } from "@/utils/properties";
import Link from "next/link";
import Marquee from "../ui/marquee";
import { TShirtWithDesignItem2 } from "./TShirtWithDesignItem";


export const SampleDesigns: string[] = [
  "skull,_lava.png",
  "a_royal_lion.png",
  "wolf,_howling,_full_moon.png",
  "ironman,_fire_in_the_background.png",
  "polar_bear,_drinking_a_cocktail,_in_a_desert.png",
  "astronaut_in_an_alien_planet.png",
  "hulk,_smashing_a_car.png",
  "cute_hippo_with_his_son.png",
  "elephant,_chasing_away_a_car,_snowy_mountains.png",
  "spider_man,_swinging_through_buildings.png",
  "a_valley_at_foothills_of_himalayas.png",
  "bear_holding_a_gun.png",
  "snowy_mountain.png",
  "rabbit,_riding_a_rocket,_in_space.png",
  "a_dolphin,_flying_through_the_clouds,_smiling.png",
  "cat,_scuba_diving_in_deep_sea.png",
  "husky,_drinking_beer.png",
  "majestic_horse,_running_at_a_beach.png",
  "bird_view_of_new_york_skyline.png",
  "fighter_jet_crashing_in_a_forest.png",
  "bulldog,_wearing_sunglasses,_smiling.png",
  "squirrel,_surfing_in_sea.png",
  "cute_pig_rolling_in_the_mud.png",
];

export const ImagesWithPrompt = SampleDesigns.map((img) => ({
  img: `/images/${img}`,
  name: img.split(".")[0].split("_").join(" "),
}));

type props = {
  reverse?: boolean;
};

export default function ImageInfiniteScroll(props: props) {
  const className = "from-black to-transparent h-5"

  return (<>
    <div className={"bg-gradient-to-t " + className} />
    <div className="h-1/4 md:h-1/3 lg:h-1/2 bg-black">

      <Marquee>

        {SampleDesigns.slice(0, 8).map((data, id) => (
          <TShirtWithDesignItem2 item={data} key={id} index={id} />
        ))}
      </Marquee>


      <div className="text-end px-2 text-white">
        <Link href={Properties.routes.EXPLORE_DESIGNS_WITH_TSHIRTS} className="underline">
          See All
        </Link>
      </div>
    </div>
    <div className={"bg-gradient-to-b " + className} />
  </>
  );
}
