import React, { useState } from 'react'
import Image from 'next/image';
import { Spinner, Tooltip } from 'flowbite-react';
import { CiCircleCheck } from 'react-icons/ci';
import { cn } from '@/utils/cn';
import ImageWithLoader from '../others/ImageWithLoader';


type props = {
    images: string[];
    selectedImages: string[];
    onImageClick: (src: string) => void;
    rainbow?: boolean
}

export default function ImageList(props: props) {
    return (
        <div className="mt-5 grid w-full grid-cols-4 gap-1 xs:grid-cols-5 md:grid-cols-6 lg:grid-cols-4 xl:grid-cols-6">
            {props.images.map((image, index) => {
                const selected = props.selectedImages.includes(image)
                return (
                    <PromptImage
                        key={image}
                        src={image}
                        selected={selected}
                        onClick={props.onImageClick}
                        rainbow={props.rainbow}
                    />
                );
            })}
        </div>
    )
}


const PromptImage = (props: {
    src: string;
    selected: boolean;
    onClick: (src: string) => void;
    rainbow?: boolean
}) => {
    const [isClicked, setIsClicked] = useState(false);


    const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
        props.onClick(props.src);
        setIsClicked(true);
    };

    return (
        <div className={cn('w-full aspect-square bg-gradient-to-tr from-slate-400 via-slate-100 to-slate-400 p-[1px] rounded-md hover:scale-[1.01] group transition-all ', {
            'rainbow-input p-0.5': props.rainbow
        })}>
            <div className={`h-full relative rounded-md bg-white transition-all group-hover:bg-slate-100 overflow-hidden border shadow-md`}>
                {props.selected && (
                    <div className="pointer-events-none absolute flex size-full items-center justify-center bg-black bg-opacity-30 text-green-200 z-20 ">
                        <CiCircleCheck className="size-10" />
                    </div>
                )}
                {!isClicked && (
                    <div className="absolute right-2 top-2 size-2 rounded-full   bg-gradient-to-b from-amber-600 to-yellow-400 z-20"></div>
                )}

                <div className='size-full relative'>

                    <ImageWithLoader
                        src={props.src}
                        spinnerSize='md'
                        alt={`Generated image`}
                        sizes='(max-width: 530px) 24vw, (max-width: 768px) 19vw, (max-width: 1024px) 10vw, 8vw'
                        fill
                        className={`z-10 cursor-pointer object-cover transition`}
                        onClick={handleClick}
                        priority={true}
                        loading='eager'
                        key={props.src}
                    />

                </div>

            </div>
        </div >

    );
};

