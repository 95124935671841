'use client'
import { useEffect, useRef } from "react";

export default function Marquee({ children }: { children: React.ReactNode }) {


  return (
    <div className="flex overflow-x-scroll noscrollbar">
      {children}
    </div>
  );
}
