'use client'
import { PlaceholdersAndVanishInput } from "@/components/ui/placeholders-and-vanish-input";
import { useNonUrlContext } from "@/contexts/NonUrlContext";
import { checkGeneratedImages, generateImages } from "@/server/server-actions";
import {
  Button,
  Modal,
  Progress,
  Tooltip,
} from "flowbite-react";
import React, {
  useEffect,
  useState,
} from "react";
import { ImagesWithPrompt } from "../others/marquee";
import ImageList from "./ImageList";
import { XCircleIcon } from "lucide-react";
import { useSession } from "next-auth/react";
import { useCommonContext } from "@/contexts/CommonContext";
import LetsTalk from "../detailComponents/letsTalk";
import * as amplitude from '@amplitude/analytics-browser'


type ImageGenerateItemProps = {
  onReset?: () => void
  selectedImages: string[];
  handlePromptImageClick: (src: string) => void;
};

const placeholders = ImagesWithPrompt.map((data) => data.name)

export const ImageGenerateItem = (props: ImageGenerateItemProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const session = useSession()
  const [errorMessage, setErrorMessage] = useState("");
  const [commonContext, setCommonContext] = useCommonContext()
  const [promptImages, setPromptImages] = React.useState<{ src: string }[]>([]);

  const [disabled, setDisabled] = useState(false);

  const [showResetModal, setShowResetModal] = useState(false);
  const { sampleImages } = useNonUrlContext()
  useEffect(() => {
    try {
      const x = localStorage.getItem('promptImages')
      const json = JSON.parse(x || '[]')
      setPromptImages(json)
    }
    catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    if (promptImages.length > 0)
      localStorage.setItem('promptImages', JSON.stringify(promptImages))
  }, [promptImages.length])

  const updateImages = async (rid: number, interval: NodeJS.Timeout) => {
    const req = await checkGeneratedImages(rid);
    if (
      req.images &&
      JSON.stringify(req.images) !== JSON.stringify(promptImages)
    ) {
      setPromptImages([...req.images.map((item) => ({ src: item.src })), ...promptImages]);
    }

    if (req.isDone) {
      setInputValue("");
      setDisabled(false);
      clearInterval(interval);
    }
  };

  const handlePromptSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    amplitude.logEvent('Prompt Sent!', { prompt: inputValue })
    if (errorMessage.length > 0) setErrorMessage("")
    if (session.status !== 'authenticated' && promptImages.length > 0) {
      setCommonContext({ loginModalOpen: true, loginModalAction: 'imageGen' })
      return
    }

    const prompt = inputValue;

    if (prompt) {
      setDisabled(true);
      const response = await generateImages(prompt, promptImages.map(item => item.src));

      if (response.requestId) {
        const interval = setInterval(() => {
          updateImages(response.requestId, interval);
        }, 1500);
      }
      else {
        setErrorMessage(response.error || "Something went wrong. Please try again later.")
        setDisabled(false)
      }
    }
  };

  const handleConfirmReset = () => {
    setShowResetModal(false);
    handleReset();
  };

  const handleReset = () => {
    setPromptImages([]);
    localStorage.setItem('promptImages', '[]')
    if (props.onReset)
      props.onReset()
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handlePromptImageClick = (src: string) => {
    props.handlePromptImageClick(src)
  }

  return (
    <div className="my-3 w-full px-0 md:px-2">
      <div className="flex flex-row items-center justify-center">
        <div className="grid w-full grid-flow-col items-center justify-stretch p-0">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            showBorder={promptImages.length === 0}
            onSubmit={handlePromptSubmit}
            onChange={handleChange}
            inputDisabled={disabled}
          />
        </div>

        <Modal
          show={showResetModal}
          onClose={() => setShowResetModal(false)}
          className="z-[100]"
        >
          <Modal.Header className="bg-gray-300 py-2 pt-3">
            Clear Results?
          </Modal.Header>
          <Modal.Body className="flex min-h-28 items-center bg-gray-200 py-2">
            <p>Are you sure you want to clear all the results?</p>
          </Modal.Body>
          <Modal.Footer className="flex justify-end bg-gray-300 py-2">
            <Button onClick={handleConfirmReset} color={'dark'} size={'sm'}>
              Yes, Clear
            </Button>
            <Button
              color="gray"
              onClick={() => setShowResetModal(false)}
              size={'sm'}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {errorMessage.length > 0 && <div className="text-red-500 text-sm md:text-base font-semibold mt-2 mx-2 text-center md:text-start">{errorMessage}</div>}

      {disabled && (
        <>
          <div className="mt-3">Generating: {inputValue}</div>
          <progress className="progress progress-primary w-full transition-all" />
          <LetsTalk />
        </>
      )}
      {promptImages.length == 0 && <ImageList rainbow images={sampleImages.map(x => x.image)} selectedImages={props.selectedImages} onImageClick={handlePromptImageClick} />}
      {promptImages.length > 0 && (
        <div className="my-2">
          <div className="flex items-center justify-between">

            <div>
              <h2 className="mt-3 text-lg font-bold dark:text-white">
                Generated Images
              </h2>
              <span className="text-gray-400">
                Select images to add to your design
              </span>
            </div>


            <Tooltip content="Clear Results" className="z-[999]">
              <div className="flex items-center justify-center rounded-full border-red-600 p-1 text-red-600 transition-all hover:bg-red-600 hover:text-white xs:border">

                <button
                  className="flex items-center justify-center text-sm"
                  color={"gray"}
                  onClick={() => setShowResetModal(true)}
                  disabled={disabled}
                >
                  <XCircleIcon className="size-6" />
                  <span className="hidden pe-2 ps-1 text-sm xs:block">Reset</span>
                </button>
              </div>
            </Tooltip>



          </div>

          <ImageList images={promptImages.map(x => x.src)} selectedImages={props.selectedImages} onImageClick={handlePromptImageClick} />
        </div>
      )}

    </div>
  );
};

