import { CustomImageContextType } from "@/contexts/CustomImageContext"
import { Properties } from "@/utils/properties"
import { ImageMode } from "@/utils/types"
import { Card } from "flowbite-react"
import Image from "next/image"
import Link from "next/link"
import { SampleDesigns } from "./marquee"

export const TShirtWithDesignItem = (props: { imageSrc: string, designSrc: string, name: string }) => {

    const defaultContext = {
        frontImages: [],
        backImages: [],
        frontMask: '/mask4.png',
        backMask: null,
        imageMode: ImageMode.NORMAL,
    } as CustomImageContextType



    return (
        <Link href={Properties.routes.PRODUCT_LIST + `?image=${encodeURIComponent(JSON.stringify({ src: props.designSrc }))}`} className="relative">
            <Card
                className="size-full cursor-pointer overflow-hidden md:max-w-sm"
                renderImage={() => (
                    <Image
                        width={250}
                        height={375}
                        loading="lazy"
                        src={props.imageSrc}
                        alt={"AI Generated Design - " + props.name}
                        className="size-full cursor-pointer"
                        style={{ aspectRatio: '250/375' }}
                        layout='fixed'
                        quality={100}
                    />
                )}
            >
                <div className="">
                    <p className="text-center text-2xl font-medium tracking-tight text-gray-900 dark:text-white md:text-xl lg:text-2xl">
                        {props.name}
                    </p>
                </div>
            </Card>
        </Link>
    )
}

export const TShirtWithDesignItem2 = (props: { item: string, index: number }) => {
    const imageSource = `/tshirt_designs/${props.item}`
    const name = props.item.split('.')[0].split('_').join(' ')
    const designSource = `/images/${props.item}`

    const arrayLength = SampleDesigns.length
    const priority = props.index < 6

    return (
        <Link href={Properties.routes.PRODUCT_LIST + `?image=${encodeURIComponent(JSON.stringify({ src: designSource }))}`} className="relative mx-2 min-h-[375px] min-w-[250px] overflow-hidden rounded-lg">

            <Image
                loading="lazy"
                width={250}
                height={375}
                src={imageSource}
                alt={"Design - " + name}
                className="cursor-pointer shadow-lg"
            />

            <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent px-1 py-2">
                <p className="text-center text-2xl font-medium tracking-tight text-white md:text-xl lg:text-2xl">
                    {name}
                </p>
            </div>

        </Link>
    )
}

